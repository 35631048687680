/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import noop from 'lodash/noop'
import React, { useState } from 'react'

import CourseDetailsModal from 'components/course/CourseDetailsModal'
import CourseActionsButton from 'components/course_actions/CourseActionsButton'
import EditableSelect from 'components/editable/EditableSelect'
import EditableText from 'components/editable/EditableText'
import EditableThumbnail from 'components/editable/EditableThumbnail'
import Highlighter from 'components/highlighter/Highlighter'
import { useContentTypes } from 'hooks/data/misc'
import useCardStyles from 'styles/card'
import { preventFollowingLink } from 'utils/misc'
import { validateTitle as schema } from 'utils/validation'

import IconOnlyStartButton from './IconOnlyStartButton'

const useCourseStyles = makeStyles(theme => ({
    courseNotPublished: {
        border: `1px solid ${theme.palette.primary.main} !important`
    },
    coursePublished: {
        border: `1px solid transparent !important`
    },
    disabled: {
        color: `${theme.palette.text.disabled} !important`,
        cursor: 'not-allowed',
        '& a': { cursor: 'not-allowed' },
        '& button': { display: 'none' }
    }
}))

interface WideCourseTileProps {
    course: Course
    userIsAuthenticated?: boolean
    onUpdate?: (...args: any) => any
    triggerMutation?: (opts: { id: number; changes: Partial<Course> }) => void
    disabled?: boolean
}

const useStyles = () => ({ ...useCourseStyles(), ...useCardStyles() })

export default function WideCourseTile({
    course,
    userIsAuthenticated = false,
    onUpdate = noop,
    triggerMutation = noop,
    disabled = false
}: WideCourseTileProps) {
    const { data: contentTypes } = useContentTypes({ courseType: true })
    const [showCourseDetailsModal, setShowCourseDetailsModal] = useState(false)
    const classes = useStyles()
    const courseUrl = disabled ? undefined : course.startButton.url

    const openCourseDetailsModal = () => {
        setShowCourseDetailsModal(true)
    }
    const closeCourseDetailsModal = () => {
        setShowCourseDetailsModal(false)
    }

    if (!contentTypes) {
        return null
    }

    return (
        <React.Fragment>
            {showCourseDetailsModal && (
                <CourseDetailsModal
                    highlights={course.highlights}
                    courseId={course.id}
                    onHide={closeCourseDetailsModal}
                    userIsAuthenticated={userIsAuthenticated}
                    triggerMutation={triggerMutation}
                />
            )}
            <div
                className={clsx([
                    course.status === 'published' ? classes.coursePublished : classes.courseNotPublished,
                    classes.card,
                    disabled && classes.disabled
                ])}
                data-testid="courseTile"
                title={disabled ? gettext('You do not have access to this course') : undefined}
            >
                <EditableThumbnail contentItem={course} name="thumbnail" triggerMutation={triggerMutation}>
                    <a href={courseUrl} className="cardImage" rel="noreferrer">
                        <img src={course.thumbnail} />
                    </a>
                </EditableThumbnail>
                {userIsAuthenticated && (
                    <div className="narrowProgress">
                        <span className="progressBar" style={{ width: `${course.completionInPercent ?? 0}%` }} />
                    </div>
                )}

                <a
                    href={courseUrl}
                    className="cardContent"
                    draggable="false" // make text selectable (beneficial while editing)
                    onClick={preventFollowingLink}
                >
                    <hgroup>
                        {course.type && (
                            <EditableSelect
                                contentItem={course}
                                triggerMutation={triggerMutation}
                                name="type"
                                options={contentTypes.map(o => o.name)}
                                defaultSelection={course.type}
                                editOnClick={false}
                            >
                                <Typography component="h4" className="overtitle">
                                    {course.type}
                                </Typography>
                            </EditableSelect>
                        )}
                        <EditableText
                            contentItem={course}
                            triggerMutation={triggerMutation}
                            name="title"
                            defaultValue={course.title}
                            editOnClick={false}
                            validationSchema={schema}
                        >
                            <Typography component="h3" title={course.title} className="title" noWrap>
                                <Highlighter searchWords={course.highlights} textToHighlight={course.title} />
                            </Typography>
                        </EditableText>
                    </hgroup>

                    <Typography title={course.metadata} className="metadata" variant="body1">
                        <Highlighter searchWords={course.highlights} textToHighlight={course.metadata} />
                    </Typography>
                </a>
                {userIsAuthenticated && (
                    <div className="wideProgress">
                        <span className="progressBar" style={{ width: `${course.completionInPercent ?? 0}%` }} />
                    </div>
                )}
                <div className="cardFooter">
                    <IconOnlyStartButton startButton={course.startButton} />
                    {userIsAuthenticated && (
                        <CourseActionsButton
                            course={course}
                            onCourseStatusChanged={onUpdate}
                            openCourseDetailsModal={openCourseDetailsModal}
                            extraActions
                            contributionActions
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}
